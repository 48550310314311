






















































































































































































.sharing_wrapper {
  width: fit-content;
  cursor: pointer;
  color: #fff;
  margin: auto;
  display: flex;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.print_now {
  width: 30px;
}
@media print {
  .sharing_wrapper {
    display: none;
  }
}
.icon {
  color: #fff;
  border-radius: 50%;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 14px;
  padding: 4px;
  margin: 0 5px;
  &.not_active {
    background-color: #fb404b;
  }
  &.active {
    background-color: #67c23a;
  }
}
.class_details_wrapper {
  .all_parts {
    .parts {
      text-align: right;
      display: flex;
      padding: 15px 8px;
      font-size: 17px;
      .part_a {
        min-width: 270px;
      }
      .part_b {
        // background-color: #fff;
        // padding: 8px;
        &.days {
          display: flex;
          gap: 10px;
          .day {
            background-color: #67c23a;
            color: #fff;
            padding: 4px 16px;
            border-radius: 10px;
          }
        }
      }
    }
    .parts:nth-child(odd) {
      background-color: #eee;
    }
    .parts:nth-child(even) {
      background-color: #fff;
    }
  }
}
